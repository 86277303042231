export const routes = [
    {
        name: 'index',
        path: ['/'],
        page: 'home'
    },
    {
        name: 'Home',
        path: ['/home'],
        page: 'home',
    },
    // {
    //     name: 'index',
    //     path: ['/sabor-limon'],
    //     page: 'product1'
    // },
    {
        mame: 'lima-limon',
        path: ['/lima-limon'],
        page: 'referencias'
    },
    {
        mame: 'limon-toronja',
        path: ['/limon-toronja'],
        page: 'referencias'
    },
    {
        mame: 'mango-pina',
        path: ['/mango-pina'],
        page: 'referencias'
    },
    {
        mame: 'frutos-rojos',
        path: ['/frutos-rojos'],
        page: 'referencias'
    }
]