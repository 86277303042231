import * as THREE from "three";

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())


// ---------------------------------------------------------------------------

const manager = new THREE.LoadingManager()
const TXloader = new THREE.TextureLoader(manager)


// ---------------------------------------------------------------------------

let imagesLoaded = 0
let imagesTotal = 0
manager.onProgress = function ( url: any, itemsLoaded: number, itemsTotal: number ) {
    imagesLoaded = itemsLoaded
    imagesTotal = itemsTotal
};
manager.onLoad = function ( ) {
    if( imagesLoaded === imagesTotal && imagesLoaded > 0 /*&& val !== 100*/) {
        //* 
        console.log(`[CR] Textures: ${imagesTotal}`) //Math.PI/8, ${(imagesRealSize / 1024 / 1024).toFixed(2)}Mb`)
    }
};

const imagesRealSizeArray = [0]
function imagesLoader(tx_name: string, encoding='sRGB') {
    let path = '/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/textures/'
    // if(isM) path = '/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/textures/min/'
    const t = TXloader
        .load(`${path}${tx_name}`, 
            (img: any) => {
                // console.log(tx_name)
            }, 
            (xhr: any) => {
                // if(!imagesRealSizeArray.includes( xhr.total )) {
                //     imagesRealSizeArray.push( xhr.total )
                // }
            },
            (error: any) => { console.error(`[ERROR] Image '${tx_name}'`) }
        )
    if (encoding === 'sRGB') t.encoding = THREE.sRGBEncoding
    if (encoding === 'Linear') t.encoding = THREE.LinearEncoding
    t.flipY = false
    // t.name = url
    t.dispose()

    return t
}

// ---------------------------------------------------------------------------

export var imagesRealSize = 0
export var imagesVal = 0

let loadFinish = false
function loadControl() {
    // imagesRealSize = 0
    // for (var y = 0; y < imagesRealSizeArray.length; y++){
    //     imagesRealSize += imagesRealSizeArray[y]
    // }

    imagesVal = Math.round( (imagesLoaded / imagesTotal) * 100)
    if( isNaN(imagesVal) ) imagesVal = 0

    if(imagesVal === 100) {
        loadFinish = true
    }
}

let r
function anim() {
    r = requestAnimationFrame( anim )
    if(loadFinish == false) {
        loadControl()
    } else {
        window.cancelAnimationFrame( r )
    }
}
anim()



// ----------------------------------------------------------------------------
// Materials ------------------------------------------------------------------

// const maxAnisotropy = new THREE.WebGLRenderer().capabilities.getMaxAnisotropy()
// let midAnisotropy = maxAnisotropy/2
// if( isNaN( midAnisotropy ) ) midAnisotropy = 0

const lata_mat = new THREE.MeshStandardMaterial({
    roughness: 0.27,
    metalness: 0.85,
    envMapIntensity: 1.25,
    // morphTargets: true,
})

export const latalimon_mat = lata_mat.clone()
latalimon_mat.map = imagesLoader('Lata_01_diff.png')
// latalimon_mat.map.anisotropy = maxAnisotropy

export const latatoronja_mat = lata_mat.clone()
latatoronja_mat.map = imagesLoader('Lata_02_diff.png')

export const latafresa_mat = lata_mat.clone()
latafresa_mat.map = imagesLoader('Lata_03_diff.png')

export const latamango_mat = lata_mat.clone()
latamango_mat.map = imagesLoader('Lata_04_diff.png')

//

export const limon_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Limon_base.jpg'),
    roughness: 0.4,
    metalness: 0.15,
    envMapIntensity: 1.5,
    normalMap: imagesLoader('Limon_normal.jpg', 'Linear'),
    normalScale: new THREE.Vector2(3, 3),
})

export const lima_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Lima_base.jpg'),
    roughness: 0.4,
    metalness: 0.35,
    envMapIntensity: 1.5,
    normalMap: imagesLoader('Lima_normal.jpg', 'Linear'),
    normalScale: new THREE.Vector2(3, 3),
})

export const toronja_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Toronja_base.jpg'),
    roughness: 0.4,
    metalness: 0.35,
    envMapIntensity: 1.5,
    normalMap: imagesLoader('Toronja_normal.jpg', 'Linear'),
    normalScale: new THREE.Vector2(5, 5),
})

export const mango_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Mango_base.jpg'),
    roughness: 0.5,
    metalness: 0.15,
    // normalMap: imagesLoader('Mango_normal.jpg', 'Linear'),
    // normalScale: new THREE.Vector2(3, 3),
})
export const pina_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Pina_base_v2.jpg'),
    roughness: 0.33,
    envMapIntensity: 1.25,
})

export const fresa_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Fresa_base.jpg'),
    roughness: 0.5,
    metalness: 0.15,
    envMapIntensity: 1.25,
})
export const cereza_mat = new THREE.MeshStandardMaterial({
    map: imagesLoader('Cereza_base.jpg'),
    roughness: 0.35,
    metalness: 0.1
})

//

export const values_mat = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    metalness: 0.85,
    envMapIntensity: 1.75
})

export const gotas_mat = new THREE.MeshPhysicalMaterial({
    color: 0xdff4f6,
    roughness: 0.02,
    metalness: 0.035,
    transmission: 1
})