import { Vector3, Euler } from 'three'

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}() )

const w = window.innerWidth
const h = window.innerHeight
const p = w/h

// ------

export let hv = {
    camera: {
        fov: 35,
        pos: {x: 0, y: 8, z: 35},
        target: {x: 0, y: 8, z: 0}
    },
    fruits: {
        0: new Vector3(-12, 24, -35),
        1: new Vector3(-16, 18, 0),
        2: new Vector3(1, 14, -15), 
        3: new Vector3(11, 2, -19),
        4: new Vector3(-8, -5, -45),
        5: new Vector3(-10, -2, 5),
        6: new Vector3(20, 20, -30),
        7: new Vector3(12, 1, 4),
        8: new Vector3(33, 31, -45),

        9: new Vector3(40, 3, -2), //
        10: new Vector3(-10, 40, -40), //
    },
    latas_cont: {
        posX: 4,
        posY: 0,
        rotY: -0.13
    },
    lata01: {
        f: new Vector3( 0, 8, 0),
        a: new Vector3( 0, 7.5, 3)
    },
    lata02: {
        f: new Vector3( -10, -2, -35),
        a: new Vector3( -5, 14, -35)
    },
    lata03: {
        f: new Vector3( 8, 10, -25),
        a: new Vector3( -7, 10, -25)
    },
    lata04: {
        f: new Vector3( 20, 14, -55),
        a: new Vector3( -12, 14, -55)
    },
    interna: {
        lata: {
            rotI: new Euler(-0.15, -0.25, -0.1),
            rotF: new Euler(-0.1, -0.1, -0.05)
        },
        frutas: {
            0: new Vector3( -8, 17, -9),
            1: new Vector3( 4, 13, -6),
            2: new Vector3( -5, 6, -4),
            3: new Vector3( 5, 3, 4),
            4: new Vector3( -6, 1, 7),
            5: new Vector3( 6, 16, 9),
            6: new Vector3( -4.5, 11, 4),
            7: new Vector3( 5, 8, 4),
        },
        limon: [1, 0, 3, 4, 1, 1, 13, 14],
        toronja: [1, 0, 6, 7, 1, 1, 13, 14],
        mango: [1, 0, 10, 9, 2, 1, 13, 14],
        fresa: [12, 0, 11, 12, 1, 1, 13, 14],
    }
}

if( isM ) {
    hv.camera.pos.z = 48

    hv.fruits[0] = new Vector3(-13, 22, -60)
    hv.fruits[3] = new Vector3(12, -3, -30)
    hv.fruits[6] = new Vector3(9, 26, -20)
    hv.fruits[8] = new Vector3(6, -13, -25)
    
    hv.lata02.f.x = -9
    hv.lata03.f.x = 7.5
    hv.lata04.f.x = 16
    hv.lata04.f.y = 18

    hv.lata01.a.y = 7.5
    hv.lata01.a.z = -1
    hv.lata02.a.x = 0
    hv.lata03.a.x = 0
    hv.lata04.a.x = 0

    hv.latas_cont.posX = -1
    hv.latas_cont.posY = -3
    hv.latas_cont.rotY = 0

    //

    hv.interna.frutas[0].x = -9.5
    hv.interna.frutas[5].y = 18
}