import * as THREE from "three";

import Stats from "three/examples/jsm/libs/stats.module"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader"

import {
    EffectComposer, RenderPass, EffectPass,
    BlendFunction,
    ToneMappingEffect, ToneMappingMode,
    NoiseEffect,
    DepthOfFieldEffect, 
    BloomEffect, KernelSize,
    // SMAAImageLoader, SMAAEffect, SMAAPreset, EdgeDetectionMode,
} from "postprocessing"

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/all";

import { hv }  from "../config/Rifada-values"
import * as mat from "../config/Rifada-mats"


// ---------------------------------------------------------------------------

let camera, scene, renderer, composer;

const GLTF_loader = new GLTFLoader()
const Env_loader = new RGBELoader()


const rifada_home = document.querySelector('#rifada-home')
const canvas_home = document.querySelector('#canvas-home')


// ---------------------------------------------------------------------------

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())

const devRot = document.querySelector('#deviceRotated')
isM ? devRot.classList.add('mobile') : devRot.remove()

const size = {
    w: 0, h: 0, halfW: 0, halfH: 0
}


// ---------------------------------------------------------------------------

const refes = ['lima-limon', 'limon-toronja', 'mango-pina', 'frutos-rojos']
const randomNum = (min=0, max=refes.length) => { return Math.floor( Math.random() * (max - min) + min ) }

// const headerMore = document.querySelector('.header-more')

// if( headerMore ) {
//     document.querySelector('.header-more').removeAttribute('href')
//     headerMore.addEventListener('pointerup', (e) => {
//         window.location = `/${refes[randomNum()]}`
//     })
// }

// document.querySelector('.outModal').style.display = 'none'

document.querySelectorAll('.button-more').forEach( (r) => {
    r.addEventListener('pointerup', (e) => {
        gsap.to('.outModal', {
            opacity: 1,
            duration: 0.5,
            ease: 'power1.in',
            onStart() {
                this.targets()[0].style.display = 'block'
            },
            onComplete() {
                window.location = `/${refes[randomNum()]}`
                // this.targets()[0].style.display = 'none'
            }
        })
    })
})


const init = () => {

    console.log('[CR] Scene init')

    function getRealSize() {
        size.w = window.innerWidth
        size.h = window.innerHeight
        size.halfW = size.w / 2
        size.halfH = size.h / 2
    }
    getRealSize()


    // ---------------------------------------------------------------------------

    const clock = new THREE.Clock()

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera( hv.camera.fov, size.w/size.h, 0.1, 150 )
    camera.position.copy( hv.camera.pos )
    camera.lookAt( new THREE.Vector3().copy( hv.camera.target ) )

    renderer = new THREE.WebGLRenderer({
        canvas: canvas_home,
        // antialias: true,
        // ----Postprocessing
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
	    depth: false,
        alpha: true,
    })
    renderer.setPixelRatio( window.devicePixelRatio )
    renderer.setSize( size.w, size.h )
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(0x00ff00, 0)

    // Extras -----
    // scene.add( new THREE.AxesHelper( 10 ) );

    // let stats = new Stats()
    // stats.dom.style.cssText = 'top: 40%; position: fixed; z-index: 999999'
    // document.body.appendChild( stats.dom )

    // let orbit = new OrbitControls( camera, renderer.domElement )
    // orbit.target.copy( hv.camera.target )
    // orbit.update()

    const ageGate = document.querySelector('.agegate-visible')
    if( ageGate ) {
        document.querySelector('.age_gate_yes').addEventListener( 'pointerup', (e) => {
            rifada_home.setAttribute('data-state', 'Ready')
            document.querySelector('#app').style.height = 'auto'
        })
    }

    //
    // Load control ----------------------------------------------------------------
    const loadedElements = { }

    let val = 0
    let loadFinish = false
    function realLoader() {
        let loaded = 0
        Object.keys(loadedElements).forEach( (key) => { loaded += loadedElements[key].loaded })
        let total = 0
        // Object.keys(loadedElements).forEach( (key) => { total += loadedElements[key].total })
        total = 396263 + 1030592 + 457480

        //

        let elements = Math.round( (loaded / total) * 100)
        if( isNaN(elements) ) elements = 0
        val = (mat.imagesVal + elements) / 2

        document.querySelector('.loadedValue').innerHTML = `${val.toFixed(0)}%`
        // document.querySelector('.loadedLine').style.width = `${val.toFixed(0)}%`


        if(val === 100) {
            // Object.keys(loadedElements).forEach( (key) => { console.log( loadedElements[key].total ) })

            console.log(`[CR] All ready at ${(clock.getElapsedTime()).toFixed(2)}s \r\n      ${( (total + mat.imagesRealSize) /1024/1024).toFixed(2)}Mb`)
            loadFinish = true

            gsap.to('.Loader', {
                opacity: 0,
                ease: 'power1.inOut',
                duration: 1.5,
                onStart() {
                    if( !ageGate ) {
                        rifada_home.setAttribute('data-state', 'Ready')
                        document.querySelector('#app').style.height = 'auto'
                    }
                },
                onComplete() {
                    this.targets()[0].remove()

                    activateScrollTrigger()
                }
            }, 1)
        }
    }


    // EnvMap ------------------------------------------------------------------

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    const envObj = "02b.hdr"
    Env_loader
        .setDataType(THREE.UnsignedByteType)
        .load(`/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/envmap/${envObj}`,
            (env) => {
                pmremGenerator.compileEquirectangularShader()
                const HDRImap = pmremGenerator.fromEquirectangular(env).texture

                scene.environment = HDRImap
                // scene.background = HDRImap

                HDRImap.dispose()
                pmremGenerator.dispose()
            },
            (xhr) => {
                loadedElements.env = { }
                loadedElements.env.loaded = xhr.loaded
                loadedElements.env.total = xhr.total

                if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] EnvMap "${envObj}" \r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }
            },
            (error) => {
                console.warn('[CR] EnvMap: Error loading')
                // console.warn( error )
            }
        )

    // GLTF ------------------------------------------------------------------
    // gltfpack -c -vt 14 -vn 10 -kn -i ./dist/assets/glb/01.glb -o ./dist/assets/glb/01c.glb

    const gltf01 = 'Lata_c.glb'
    let lata_models = []
    GLTF_loader
        .setMeshoptDecoder(MeshoptDecoder)
        .load( `/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/${gltf01}`,
            (gltf) => {
                const model = gltf.scene

                //

                const objmat = model.children[0].children[0].material.map

                lata_models.push( model.children[0].clone() )
                mat.latamango_mat.map.repeat.copy( objmat.repeat )
                lata_models[0].children[0].material = mat.latamango_mat 

                lata_models.push( model.children[0].clone() )
                mat.latatoronja_mat.map.repeat.copy( objmat.repeat )
                lata_models[1].children[0].material = mat.latatoronja_mat

                lata_models.push( model.children[0].clone() )
                mat.latafresa_mat.map.repeat.copy( objmat.repeat )
                lata_models[2].children[0].material = mat.latafresa_mat
                
                lata_models.push( model.children[0].clone() )
                mat.latalimon_mat.map.repeat.copy( objmat.repeat )
                lata_models[3].children[0].material = mat.latalimon_mat

                latasInitialSetUp()
            },
            (xhr) => {
                loadedElements.glb_lata = { }
                loadedElements.glb_lata.loaded = xhr.loaded
                loadedElements.glb_lata.total = xhr.total

                /*if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] glb_lata "${gltf01}"\r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }*/
            },
            (error) => {
                console.warn('[CR] glb_lata: Error loading')
                // console.warn( error )
            }
        )

    const gltf02 = 'Frutas_c.glb'
    let fruit_models = []
    GLTF_loader
        .setMeshoptDecoder(MeshoptDecoder)
        .load( `/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/${gltf02}`,
            (gltf) => {
                const model = gltf.scene

                const vr = model.getObjectByName('Limon_01C').children[0].material.map.repeat
                mat.limon_mat.map.repeat = vr
                mat.lima_mat.map.repeat = vr
                mat.toronja_mat.map.repeat = vr
                mat.mango_mat.map.repeat = vr
                mat.pina_mat.map.repeat = vr
                mat.fresa_mat.map.repeat = vr
                mat.cereza_mat.map.repeat = vr


                // fruit_models.push( model.getObjectByName('Limon_01C').clone() )
                fruit_models.push( model.getObjectByName('Limon_02M').clone() )
                fruit_models.push( model.getObjectByName('Limon_03R').clone() )
                fruit_models.push( model.getObjectByName('Limon_04T').clone() )
                fruit_models[0].children[0].material = mat.limon_mat
                fruit_models[1].children[0].material = mat.limon_mat
                fruit_models[2].children[0].material = mat.limon_mat

                // fruit_models.push( model.getObjectByName('Lima_01C').clone() )
                fruit_models.push( model.getObjectByName('Limon_02M').clone() )
                fruit_models.push( model.getObjectByName('Limon_03R').clone() )
                fruit_models.push( model.getObjectByName('Limon_04T').clone() )
                fruit_models[3].name = 'Lima_02M'
                fruit_models[4].name = 'Lima_03R'
                fruit_models[5].name = 'Lima_04T'
                fruit_models[3].children[0].material = mat.lima_mat
                fruit_models[4].children[0].material = mat.lima_mat
                fruit_models[5].children[0].material = mat.lima_mat

                // fruit_models.push( model.getObjectByName('Toronja_01C').clone() )
                fruit_models.push( model.getObjectByName('Toronja_02M').clone() )
                fruit_models.push( model.getObjectByName('Toronja_03R').clone() )
                fruit_models.push( model.getObjectByName('Toronja_04T').clone() )
                fruit_models[6].children[0].material = mat.toronja_mat
                fruit_models[7].children[0].material = mat.toronja_mat
                fruit_models[8].children[0].material = mat.toronja_mat

                fruit_models.push( model.getObjectByName('Mango_').clone() )
                fruit_models[9].children[0].material = mat.mango_mat

                fruit_models.push( model.getObjectByName('Pina_').clone() )
                fruit_models[10].children[0].material = mat.pina_mat

                fruit_models.push( model.getObjectByName('Fresa_').clone() )
                fruit_models[11].children[0].material = mat.fresa_mat

                fruit_models.push( model.getObjectByName('Cereza_').clone() )
                fruit_models[12].children[0].material = mat.cereza_mat

                createFruits()

                // for(let i = 0; i < fruit_models.length; i++) {
                //     fruit_models[i].visible = false
                // }

            },
            (xhr) => {
                loadedElements.glb_frutas = { }
                loadedElements.glb_frutas.loaded = xhr.loaded
                loadedElements.glb_frutas.total = xhr.total

                /*if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] glb_frutas "${gltf02}"\r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }*/
            },
            (error) => {
                console.warn('[CR] glb_frutas: Error loading')
                // console.warn( error )
            }
        )

    
    //
    // SET UP
    
    let lata_principal
    let latas_secundarias = []

    const latas_cont = new THREE.Object3D()
    let latas_cont_posX = hv.latas_cont.posX
    let latas_cont_rotY = hv.latas_cont.rotY
    function latasInitialSetUp() {

        lata_principal = lata_models[3]
        latas_secundarias[0] = lata_models[0] 
        latas_secundarias[1] = lata_models[1]
        latas_secundarias[2] = lata_models[2]

        lata_principal.position.copy( hv.lata01.f )
        lata_principal.rotation.set( 0.1, -0.1, -0.1 )
        latas_cont.add( lata_principal )

        latas_secundarias[0].position.copy( hv.lata02.f )
        latas_secundarias[0].rotation.set( 0.15, 0.2, 0.1)
        latas_cont.add( latas_secundarias[0] )

        latas_secundarias[1].position.copy( hv.lata03.f )
        latas_secundarias[1].rotation.set( 0.15, -0.2, 0.2)
        latas_cont.add( latas_secundarias[1] )

        latas_secundarias[2].position.copy( hv.lata04.f )
        latas_secundarias[2].rotation.set( -0.2, -0.5, -0.2)
        latas_cont.add( latas_secundarias[2] )

        //

        latas_cont.position.x = latas_cont_posX
        latas_cont.position.y = hv.latas_cont.posY
        latas_cont.rotation.y = latas_cont_rotY

        scene.add( latas_cont )

        animateLatas()
    }

    let t_loop
    function animateLatas() {

        t_loop = gsap.timeline({
            defaults: {
                ease: 'power1.inOut',
                repeat: -1,
                yoyo: true
            }
        })
        
        t_loop.to( lata_principal.children[0].position, {
            y: -7.4,
            duration: 2,
        }, 0)
        t_loop.to( lata_principal.rotation, {
            x: -0.15,
            y: -0.2,
            duration: 5,
        }, 0)

        t_loop.to( latas_secundarias[0].children[0].position, {
            y: -7.3,
            duration: 3,
        }, 0)
        t_loop.to( latas_secundarias[0].rotation, {
            x: 0.1,
            y: 0,
            duration: 4,
        }, 0)

        t_loop.to( latas_secundarias[1].children[0].position, {
            y: -7.3,
            duration: 2.5,
        }, 0)
        t_loop.to( latas_secundarias[1].rotation, {
            x: 0.2,
            y: -0.05,
            duration: 3.5,
        }, 0)

        t_loop.to( latas_secundarias[2].children[0].position, {
            y: -9,
            duration: 4.5,
        }, 0)
        t_loop.to( latas_secundarias[2].rotation, {
            x: -0.4,
            y: -0.25,
            duration: 4,
        }, 0)

    }

    const fruits = []
    const fruits_cont = new THREE.Object3D()
    function createFruits() {

        const randomRot = (min=0, max=Math.PI/4) => { return Math.random() * (max - min) + min }
        
        fruits[0] = fruit_models[4] // 5
        fruits[1] = fruit_models[6]
        fruits[2] = fruit_models[2]
        fruits[3] = fruit_models[3]
        fruits[4] = fruit_models[7]
        fruits[5] = fruit_models[10]
        fruits[6] = fruit_models[7].clone()
        fruits[7] = fruit_models[1] // 12
        fruits[8] = fruit_models[11]

        const amount = fruits.length
        
        for(let i = 0; i < amount; i++) {
            fruits[i].position.copy( hv.fruits[i] )
            fruits[i].rotation.set( 0, randomRot(), randomRot() )
        }
        // fruits[0].rotation.y = 0
        fruits[1].rotation.y = 1.5
        fruits[3].scale.set(0.9, 0.9, 0.9)

        fruits.forEach( (elem) => {

            gsap.to( elem.rotation, {
                x: `+=${randomRot(1, 4)}`,
                z: `+=${randomRot(2, 5)}`,
                ease: 'power1.inOut',
                repeat: -1,
                duration: randomRot(15, 20),
                yoyo: true
            })

            fruits_cont.add( elem )
        })

        scene.add( fruits_cont )

        // activateScrollTrigger()
    }

    function activateScrollTrigger() {
        gsap.registerPlugin(ScrollTrigger)

        // let scrollDirection

        const tl01 = gsap.timeline({
            defaults: {
                ease: 'power1.out'
            },
            scrollTrigger: {
                trigger: rifada_home,
                start: 'top top',
                end: '1200% bottom',
                pin: true,
                scrub: 0.1,
                toggleActions: "restart pause resume pause",
                snap: {
                    snapTo: "labels", // snap to the closest label in the timeline
                    duration: {min: 0.2, max: 2}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                    delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                    ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                  }
            },
            onUpdate() {
                const time = tl01.time()
                if( time > 0.21 ) { t_loop.pause() } else { t_loop.resume() }
                // console.log( time )
                document.querySelector('.progress-bar').style.height = this.progress() * 100 + '%'
            }
        })

        //

        tl01.addLabel('Start', 0)
        tl01.addLabel('Sec-01', 3.5)
        tl01.addLabel('Sec-02', 8.5)
        tl01.addLabel('Sec-03', 13)
        tl01.addLabel('Sec-04', 17)
        tl01.addLabel('End', 26.5)


        // Main
        tl01.to( rifada_home, {
            backgroundColor: '#FCCA12',
            duration: 1
        }, 1)

        // Scroll
        tl01.to( '.scroll-cta', {
            y: '+=50px',
            opacity: 0,
            duration: 0.5,
        }, 0.5 )
        
        // Texto
        tl01.to( '.sec-01', {
            x: '-=50px',
            opacity: 0,
            duration: 1,
            onUpdate() {
                this.targets()[0].style.display = 'block'
            },
            onComplete() {
                this.targets()[0].style.display = 'none'
            }
        }, 0.25)

        // Contenedor
        tl01.to( latas_cont.position, {
            x: 0.5,
            y: 0,
            z: -3,
            duration: 2
        }, 0.5)

        // Latas secundarias
        latas_secundarias.forEach( (elem) => {
            tl01.to( elem.rotation, {
                x: - Math.PI,
                duration: 1.5
            }, 0.5)
            tl01.to( elem.scale, {
                x: 0.1,
                y: 0.1,
                z: 0.1,
                duration: 1.5,
                onUpdate() {
                    if( this.progress() > 0.95 ) {
                        latas_secundarias[0].visible = false
                        latas_secundarias[1].visible = false
                        latas_secundarias[2].visible = false
                    } else {
                        latas_secundarias[0].visible = true
                        latas_secundarias[1].visible = true
                        latas_secundarias[2].visible = true
                    }
                }
             }, 0.5)
        })

        tl01.to( latas_secundarias[0].position, {
            x: hv.lata02.a.x,
            y: hv.lata02.a.y,
            duration: 1.5
        }, 0.5)
        tl01.to( latas_secundarias[1].position, {
            x: hv.lata03.a.x,
            duration: 1.5
        }, 0.5)
        tl01.to( latas_secundarias[2].position, {
            x: hv.lata03.a.x,
            duration: 1.5
        }, 0.5)

            const tsec01 = gsap.timeline({
                defaults: {
                    ease: 'power1.inOut',
                    duration: 1,
                    repeat: -1,
                    yoyo: true
                },
                paused: true
            })
            tsec01.to(lata_principal.position, {
                y: '+=0.5',
                duration: 0.5
            }, 0)
            tsec01.to(lata_principal.rotation, {
                z: 0.15
            }, 0)

        // Lata principal
        tl01.to(lata_principal.rotation, {
            x: -0.1,
            y: (Math.PI * 2) - 0.1,
            duration: 2
        }, 0.5)
        tl01.to(lata_principal.position, {
            y: hv.lata01.a.y,
            z: hv.lata01.a.z,
            duration: 1.5,
            // Pers-in
            onUpdate() {
                tsec01.pause(); tsec01.progress(0)
            },
            onComplete() {
                tsec01.play()
            }
        }, 1)

        // Frutas
        tl01.to( fruits[0].position, {
            x: '-=3',
            z: '+=14',
            duration: 2
        }, 0.5)
        tl01.to( fruits[1].position, {
            x: '-=1',
            duration: 2
        }, 0.5)
        tl01.to( fruits[2].position, {
            x: '+=5',
            y: '+=3',
            z: '-=14',
            duration: 2
        }, 0.5)
        tl01.to( fruits[3].position, {
            x: '+=3',
            y: '-=2',
            z: '+=1',
            duration: 2
        }, 0.5)
        tl01.to( fruits[4].position, {
            x: '-=6',
            z: '+=4',
            duration: 2
        }, 0.5)
        tl01.to( fruits[5].position, {
            x: '-=4',
            duration: 2
        }, 0.5)
        tl01.to( fruits[6].position, {
            x: '+=2',
            duration: 2
        }, 0.5)
        tl01.to( fruits[7].position, {
            // x: '+=2',
            y: '-=2',
            z: '+=2',
            duration: 2
        }, 0.5)
        tl01.to( fruits[8].position, {
            x: '+=4',
            duration: 2
        }, 0.5)

        // Splash
        // tl01.to( document.querySelector('.splash-01'), {
        //     x: '-=3%',
        //     duration: 2.5
        // }, 0.5)
        // tl01.to( document.querySelector('.splash-02'), {
        //     x: '+=3%',
        //     duration: 2.5
        // }, 0.5)


        //

        // Sec-02-01
        tl01.to( '.sec-02-background', {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: 'back.out(3)'
        }, 1.5)
        tl01.from( '.sec-02-01-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.1 ) {this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 2)
        tl01.from( '.sec-02-01-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 2.5)

        tl01.to( '.sec-02-01-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 4.5+1)
        tl01.to( '.sec-02-01-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 4.5+1)


        // 02-02
        tl01.to( '.sec-02-background', {
            backgroundColor: '#FD9D0D',
            duration: 1.5
        }, 4.25+1)

            const s02_cont = new THREE.Object3D()

            s02_cont.add( lata_principal.children[0].clone() )
            s02_cont.add( latas_secundarias[0].children[0].clone() )
            s02_cont.add( latas_secundarias[1].children[0].clone() )
            s02_cont.add( latas_secundarias[2].children[0].clone() )

            const v = 15
            s02_cont.children[0].position.set( 0, 0, v)
            s02_cont.children[1].position.set( -v, 0, 0)
            s02_cont.children[2].position.set( 0, 0, -v)
            s02_cont.children[3].position.set( v, 0, 0)

            s02_cont.traverse( (el) => {
                el.rotation.set( 0, 0, 0 )
            })

            s02_cont.position.set( -1.5, 5.5, hv.lata01.a.z - 5 )
            if(isM) s02_cont.position.x = -0.75
            s02_cont.scale.set( 0.01, 0.01, 0.01)
            s02_cont.rotation.set(-0.15,0,0.05)

            s02_cont.visible = false

            scene.add( s02_cont )

            gsap.to(s02_cont.rotation, {
                y: Math.PI * 2,
                duration: 10,
                ease: 'none',
                repeat: -1
            })
            s02_cont.traverse( (el) => {
                if ( el.isMesh ) {
                    gsap.to(el.rotation, {
                        y: -Math.PI * 2,
                        duration: 10,
                        ease: 'none',
                        repeat: -1
                    })
                }
            })

            const tsec02 = gsap.timeline({
                defaults: {
                    ease: 'power1.inOut',
                },
                paused: true
            })
            tsec02.to(lata_principal.position, {
                y: '+=0.25',
                duration: 1,
                repeat: -1,
                yoyo: true
            }, 0)

        tl01.to(lata_principal.rotation, {
            x: -0.2,
            y: Math.PI * 4,
            z: 0,
            duration: 1.5,
            // Pers-out
            onStart() {
                tsec01.pause(); tsec01.progress(0)
            },
            onReverseComplete() {
                tsec01.play()
            },
            //
            onUpdate() {
                if( this.progress() < 0.5 ) {
                    lata_principal.children[0].material = mat.latalimon_mat
                } else {
                    lata_principal.children[0].material = mat.latamango_mat
                }

                // Pers-in
                tsec02.pause(); tsec02.progress(0)
            },
            // Pers-in
            onComplete() {
                tsec02.play()
            }
        }, 4.25+1)
        tl01.to(s02_cont.scale, {
            x: 0.33,
            y: 0.33,
            z: 0.33,
            duration: 1,
            onReverseComplete() {
                s02_cont.visible = false
            },
            onStart() {
                s02_cont.visible = true
            },
        }, 5.5+1)

        tl01.from( '.sec-02-02-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 5.5+1)
        tl01.from( '.sec-02-02-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 6+1)

        tl01.to( '.sec-02-02-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 8+2)
        tl01.to( '.sec-02-02-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 8+2)

        tl01.to(s02_cont.scale, {
            x: 0.01,
            y: 0.01,
            z: 0.01,
            duration: 1,
            onUpdate() {
                s02_cont.visible = true
            },
            onComplete() {
                s02_cont.visible = false
            },
        }, 7.75+2)


        // 02-03
        tl01.to( '.sec-02-background', {
            backgroundColor: '#FD9BA6',
            duration: 1.5
        }, 7.75+2)

            const tsec03 = gsap.timeline({
                defaults: {
                    ease: 'back.inOut(5)',
                },
                paused: true
            })

            // let morph = { x: 0 }
            // tsec03.to( morph, {
            //     x: 0.95,
            //     duration: .5,
            //     onUpdate() {
            //         lata_principal.children[0].morphTargetInfluences[ 0 ] = this.targets()[0].x
            //     },
            // }, 0.2)
            let ts03r = { x: 0.15, y: 0.2, z: 0.1 }
            if(isM) ts03r = { x: 0.15, y: 0.05, z: 0.25 }
            tsec03.to( lata_principal.rotation, {
                x: `-=${ts03r.x}`,
                y: `+=${ts03r.y}`,
                z: `+=${ts03r.z}`,
                duration: 0.65
            }, 0.2)

        tl01.to(lata_principal.rotation, {
            x: -0.1,
            y: Math.PI * 2,
            z: -0.1,
            duration: 1.5,
            // Pers-out
            onStart() {
                tsec02.pause(); tsec02.progress(0)
            },
            onReverseComplete() {
                tsec02.play()
            },
            //
            onUpdate() {
                if( this.progress() < 0.5 ) {
                    lata_principal.children[0].material = mat.latamango_mat
                } else {
                    lata_principal.children[0].material = mat.latatoronja_mat
                }

                // Pers-in
                tsec03.pause(); tsec03.progress(0)
            },
            // Pers-in
            onComplete() {
                tsec03.play()
            }
        }, 7.75+2)

        tl01.from( '.sec-02-03-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 9+2)
        tl01.from( '.sec-02-03-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 9.5+2)

        tl01.to( '.sec-02-03-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 11.5+3)
        tl01.to( '.sec-02-03-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 11.5+3)


        // 02-04
        tl01.to( '.sec-02-background', {
            backgroundColor: '#F3303E',
            duration: 1.5
        }, 11.25+3)

            const tsec04 = gsap.timeline({
                defaults: {
                    ease: 'power1.inOut',
                    repeat: -1,
                    yoyo: true
                },
                paused: true
            })
            tsec04.to(lata_principal.position, {
                y: '+=1',
                duration: 1,
                ease: 'power1.out',
            }, 0)
            tsec04.to(lata_principal.rotation, {
                x: '-=0.3',
                y: `+=${Math.PI}`,
                z: '-=0.1',
                duration: 2
            }, 0)

        // tl01.to(lata_principal.position, {
        //     y: '-=0.5',
        //     duration: 1.5,
        // }, 11.25+3)
        tl01.to(lata_principal.rotation, {
            x: 0.1,
            y: Math.PI * 4,
            z: 0.05,
            duration: 1.5,
            // Pers-out
            onStart() {
                tsec03.pause(); tsec03.progress(0)
            },
            onReverseComplete() {
                tsec03.play()
            },
            //
            onUpdate() {
                if( this.progress() < 0.5 ) {
                    lata_principal.children[0].material = mat.latatoronja_mat
                } else {
                    lata_principal.children[0].material = mat.latafresa_mat
                }

                // Pers-in
                tsec04.pause(); tsec04.progress(0)
            },
            // Pers-in
            onComplete() {
                tsec04.play()
            }
        }, 11.25+3)

        tl01.from( '.sec-02-04-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 12.5+3)
        tl01.from( '.sec-02-04-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() < 0.05 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 13+3)

        tl01.to( '.sec-02-04-sphere', {
            scale: 0.75,
            opacity: 0,
            duration: 1.5,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 15+4)
        tl01.to( '.sec-02-04-text', {
            scale: 0.75,
            opacity: 0,
            duration: 1.5,
            ease: 'back.out(3)',
            onUpdate() {
                if( this.progress() > 0.95 ) { this.targets()[0].style.display = 'none' } else { this.targets()[0].style.display = 'block' }
            }
        }, 15+4)

        //

        tl01.to( rifada_home, {
            backgroundColor: '#F3303E',
            duration: 1.5
        }, 16+4)

        tl01.to( '.sec-02-background', {
            scale: 0.5,
            opacity: 0,
            duration: 2,
            ease: 'back.out(3)'
        }, 15.5+4)

        if(isM) {
            tl01.to( latas_cont.rotation, {
                y: -0.13,
                duration: 3
            }, 16+4)
            tl01.to( latas_cont.position, {
                y: 1,
                z: -14,
                duration: 3
            }, 16+4)
        }

        tl01.to(lata_principal.rotation, {
            x: - Math.PI * 2,
            y: 0.1,
            z: 0.05,
            duration: 3,
            // Pers-out
            onStart() {
                tsec04.pause(); tsec04.progress(0)
            },
            onReverseComplete() {
                tsec04.play()
            },
            //
            onUpdate() {
                if( this.progress() < 0.5 ) {
                    lata_principal.children[0].material = mat.latafresa_mat
                } else {
                    lata_principal.children[0].material = mat.latalimon_mat
                }
            }
        }, 16+4)
        tl01.to(lata_principal.position, { keyframes: [
            { y: 18, z: -3, x: -1.5, duration: 1.5},
            { y: 8, z: -7, x: -3.5, duration: 1.25}
        ]}, 16+4)
        tl01.to(lata_principal.scale, {
            x: 0.9,
            y: 0.9,
            z: 0.9,
            duration: 3,
            onUpdate() {
                if( this.progress() > 0.95 ) {
                    latas_secundarias[0].visible = true
                    latas_secundarias[1].visible = true
                    latas_secundarias[2].visible = true
                } else {
                    latas_secundarias[0].visible = false
                    latas_secundarias[1].visible = false
                    latas_secundarias[2].visible = false
                }
            }
        }, 16+4)

        tl01.to( latas_secundarias[0].scale, {
            x: 0.92,
            y: 0.92,
            z: 0.92,
            duration: 1.5,
        }, 18.5+4)
        tl01.to( latas_secundarias[0].position, {
            x: 1,
            y: 8,
            z: -13,
            duration: 1.5
        }, 18.5+4)
        tl01.to( latas_secundarias[0].rotation, {
            x: 0.15,
            y: -0.15,
            z: -0.05,
            duration: 1.5
        }, 18.5+4)

        tl01.to( latas_secundarias[1].scale, {
            x: 0.9,
            y: 0.9,
            z: 0.9,
            duration: 1.5,
        }, 19+4)
        tl01.to( latas_secundarias[1].position, {
            x: -10.75,
            y: 7.75,
            z: -15,
            duration: 1.5
        }, 19+4)
        tl01.to( latas_secundarias[1].rotation, {
            x: -0.15,
            y: 0.35,
            z: 0.2,
            duration: 1.5
        }, 19+4)

        tl01.to( latas_secundarias[2].scale, {
            x: 1,
            y: 1,
            z: 1,
            duration: 1.5,
        }, 19.5+4)
        tl01.to( latas_secundarias[2].position, {
            x: 6,
            y: 9,
            z: -25,
            duration: 1.5
        }, 19.5+4)
        tl01.to( latas_secundarias[2].rotation, {
            x: -0.15,
            y: 0.25,
            z: -0.15,
            duration: 1.5
        }, 19.5+4)

        tl01.from( '.sec-03-backText', {
            y: '-=50px',
            opacity: 0,
            duration: 1.5,
            onUpdate() {
                if( this.progress() < 0.1 ) {
                    this.targets()[0].style.display = 'none'
                } else {
                    this.targets()[0].style.display = 'block'
                }
            }
        }, 18.5+4)
        tl01.from( '.sec-03-frontText', {
            y: '-=50px',
            opacity: 0,
            duration: 1.5,
            onUpdate() {
                if( this.progress() < 0.1 ) {
                    this.targets()[0].style.display = 'none'
                } else {
                    this.targets()[0].style.display = 'block'
                }
            }
        }, 19.5+4)

        tl01.from( '.sec-03-buttons', {
            y: '-=50px',
            opacity: 0,
            duration: 1.5,
            onUpdate() {
                if( this.progress() < 0.1 ) {
                    this.targets()[0].style.display = 'none'
                } else {
                    this.targets()[0].style.display = 'block'
                }
            }
        }, 21+4)
    }

    const latas_euler = new THREE.Euler( 0, 0, 0, 'YXZ' );
    const fruits_euler = new THREE.Euler( 0, 0, 0, 'YXZ' );

    function lerp (start, end, amt){
        return (1-amt)*start+amt*end
    }

    function updateCamera() {
        // latas_cont.rotation.y = latas_cont_rotY + ( mousePosition.x * 0.015 )
        // latas_cont.rotation.x = mousePosition.y *  0.015

        // fruits_cont.rotation.y = ( mousePosition.x * 0.015 ) * 2
        // fruits_cont.rotation.x = latas_cont.rotation.x * 2

        //

        if( !mouseMoved ) return

        const amt = 0.03

        latas_euler.setFromQuaternion( latas_cont.quaternion )
        latas_euler.y = lerp(latas_euler.y, mousePosition.x * 0.015, amt)
        latas_euler.x = lerp(latas_euler.x, mousePosition.y * 0.015, amt)
        latas_cont.quaternion.setFromEuler( latas_euler )

        // fruits_euler.setFromQuaternion( fruits_cont.quaternion )
        // fruits_euler.y = lerp(fruits_euler.y, mousePosition.x * 0.03, amt)
        // // fruits_euler.x = lerp(fruits_euler.x, mousePosition.y * 0.03, amt)
        // fruits_euler.x = latas_cont.rotation.x * 2
        // fruits_cont.quaternion.setFromEuler( fruits_euler );
    }
    // setInterval( updateCamera, 1000/60 )



    //
    // Desktop------------------------------------------------------------------

    let mousePosition = {x: size.halfW, y: 0}
    let mouseMoved = false

    function onMouseDown(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                //
                break
        }
    }

    function onMouseMovement(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                // To camera movement + raycast
                let rect = renderer.domElement.getBoundingClientRect()
                mousePosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                mousePosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                /*
                latas_cont.rotation.y = latas_cont_rotY + ( mousePosition.x * 0.015 )
                latas_cont.rotation.x = mousePosition.y *  0.015
                fruits_cont.rotation.y = ( mousePosition.x * 0.015 ) * 2
                fruits_cont.rotation.x = latas_cont.rotation.x * 2
                */

                mouseMoved = true

                break
        }
    }

    rifada_home.addEventListener( 'pointerdown', onMouseDown, false)
    rifada_home.addEventListener( 'pointermove', onMouseMovement, false )



    // Render ------------------------------------------------------------------

    const renderPass = new RenderPass(scene, camera)

    const noiseEffect = new NoiseEffect({ premultiply: true })
    noiseEffect.blendMode.opacity.value = 0.5

    const toneMappingEffect = new ToneMappingEffect({
        mode: ToneMappingMode.ACES_FILMIC,
        resolution: 128
        // blendFunction: BlendFunction.NORMAL
    })

    const bloomEffect = new BloomEffect({
        intensity: 0.15,
        luminanceThreshold: 0.25,
        luminanceSmoothing: 0.7,
        kernelSize: KernelSize.HUGE
    })

    const dofEffect = new DepthOfFieldEffect( camera, {
        focusDistance: 0.15,
        focalLength: 0.45,
        bokehScale: 4
    })

    const ePass = new EffectPass( 
        camera,
        // noiseEffect,
        // dofEffect,
        bloomEffect,
        toneMappingEffect,
    )

    composer = new EffectComposer(renderer, {
        frameBufferType: THREE.HalfFloatType
    })
    composer.addPass( renderPass )
    composer.addPass( ePass )

    // ---------------------------------------------------------------------------

    function onWindowResize() {
        /* if(!isM) */getRealSize()
        
        camera.aspect = size.w / size.h
        camera.updateProjectionMatrix()
        renderer.setSize( size.w, size.h )
        composer.setSize( size.w, size.h )
    }
    window.addEventListener( 'resize', onWindowResize, false)


    // ---------------------------------------------------------------------------

    function animate() {
        requestAnimationFrame( animate )

        // stats.update()

        if(loadFinish == false) realLoader()

        //

        composer.render( clock.getDelta() )
        // renderer.render( scene, camera )
    }
    animate()
}

export { init }