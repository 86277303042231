import * as THREE from "three";

import Stats from "three/examples/jsm/libs/stats.module"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader"

import {
    EffectComposer, RenderPass, EffectPass,
    BlendFunction,
    ToneMappingEffect, ToneMappingMode,
    NoiseEffect,
    DepthOfFieldEffect, 
    BloomEffect, KernelSize,
    // SMAAImageLoader, SMAAEffect, SMAAPreset, EdgeDetectionMode,
} from "postprocessing"

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/all";

import { hv }  from "../config/Rifada-values"
import * as mat from "../config/Rifada-mats"


// ---------------------------------------------------------------------------

let camera, scene, renderer, composer;

const GLTF_loader = new GLTFLoader()
const Env_loader = new RGBELoader()

const rifada_referencia = document.querySelector('#rifada-referencia')
const canvas_referencia = document.querySelector('#canvas-referencia')


// ---------------------------------------------------------------------------

const isM = (function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}())

const devRot = document.querySelector('#deviceRotated')
isM ? devRot.classList.add('mobile') : devRot.remove()

const size = {
    w: 0, h: 0, halfW: 0, halfH: 0
}


// ---------------------------------------------------------------------------

const origin = location.pathname.split('/')[1]
const refes = ['lima-limon', 'limon-toronja', 'mango-pina', 'frutos-rojos']
let refeSetings = {}

switch (origin) {
    case refes[0]:
        refeSetings.mat = mat.latalimon_mat
        refeSetings.prev = 'frutos-rojos'
        refeSetings.next = 'limon-toronja'
        refeSetings.pos = 0
        refeSetings.fruits = hv.interna.limon
        break;
    case refes[1]:
        refeSetings.mat = mat.latatoronja_mat
        refeSetings.prev = 'lima-limon'
        refeSetings.next = 'mango-pina'
        refeSetings.pos = 1
        refeSetings.fruits = hv.interna.toronja
        break;
    case refes[2]:
        refeSetings.mat = mat.latamango_mat
        refeSetings.prev = 'limon-toronja'
        refeSetings.next = 'frutos-rojos'
        refeSetings.pos = 2
        refeSetings.fruits = hv.interna.mango
        break;
    case refes[3]:
        refeSetings.mat = mat.latafresa_mat
        refeSetings.prev = 'mango-pina'
        refeSetings.next = 'lima-limon'
        refeSetings.pos = 3
        refeSetings.fruits = hv.interna.fresa
        break;

    default:
        console.error('NO origin match')
        break;
}
rifada_referencia.setAttribute('data-refe', origin)

document.querySelector('.carrousel-prev').addEventListener('pointerup', (e) => {
    gsap.to('.outModal', {
        opacity: 1,
        duration: 0.5,
        ease: 'power1.in',
        onStart() {
            this.targets()[0].style.display = 'block'
        },
        onComplete() {
            window.location = `/${refeSetings.prev}`
        }
    })
})
document.querySelector('.carrousel-next').addEventListener('pointerup', (e) => {
    gsap.to('.outModal', {
        opacity: 1,
        duration: 0.5,
        ease: 'power1.in',
        onStart() {
            this.targets()[0].style.display = 'block'
        },
        onComplete() {
            window.location = `/${refeSetings.next}`
        }
    })
})

const pagination = document.querySelector('.pagination')

for(let i = 0; i < refes.length; i++) {
    let sp = document.createElement("span")
    pagination.append(sp)

    if( origin !== refes[i] ) {
        pagination.children[i].addEventListener( 'pointerup', (e) => {
            gsap.to('.outModal', {
                opacity: 1,
                duration: 0.5,
                ease: 'power1.in',
                onStart() {
                    this.targets()[0].style.display = 'block'
                },
                onComplete() {
                    window.location = `/${refes[i]}`
                }
            })
        })
    }
}

pagination.children[refeSetings.pos].classList.add('active')


// ---------------------------------------------------------------------------

const init = () => {

    console.log('[CR] Scene init')

    function getRealSize() {
        size.w = window.innerWidth
        size.h = window.innerHeight
        size.halfW = size.w / 2
        size.halfH = size.h / 2
    }
    getRealSize()


    // ---------------------------------------------------------------------------

    const clock = new THREE.Clock()

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera( hv.camera.fov, size.w/size.h, 0.1, 150 )
    camera.position.copy( hv.camera.pos )
    camera.lookAt( new THREE.Vector3().copy( hv.camera.target ) )

    renderer = new THREE.WebGLRenderer({
        canvas: canvas_referencia,
        // antialias: true,
        // ----Postprocessing
        powerPreference: "high-performance",
        antialias: false,
        stencil: false,
	    depth: false,
        alpha: true,
    })
    renderer.setPixelRatio( window.devicePixelRatio )
    renderer.setSize( size.w, size.h )
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(0x00ff00, 0)

    // Extras -----
    // scene.add( new THREE.AxesHelper( 10 ) );

    // let stats = new Stats()
    // stats.dom.style.cssText = 'top: 40%; position: fixed; z-index: 999999'
    // document.body.appendChild( stats.dom )

    // let orbit = new OrbitControls( camera, renderer.domElement )
    // orbit.target.copy( hv.camera.target )
    // orbit.update()


    //
    // Load control ----------------------------------------------------------------
    const loadedElements = { }

    let val = 0
    let loadFinish = false
    function realLoader() {
        let loaded = 0
        Object.keys(loadedElements).forEach( (key) => { loaded += loadedElements[key].loaded })
        let total = 0
        Object.keys(loadedElements).forEach( (key) => { total += loadedElements[key].total })

        //

        let elements = Math.round( (loaded / total) * 100)
        if( isNaN(elements) ) elements = 0
        val = (mat.imagesVal + elements) / 2

        document.querySelector('.loadedValue').innerHTML = `${val.toFixed(0)}%`
        // document.querySelector('.loadedLine').style.width = `${val.toFixed(0)}%`

        if(val === 100) {
            console.log(`[CR] All ready at ${(clock.getElapsedTime()).toFixed(2)}s \r\n      ${( (total + mat.imagesRealSize) /1024/1024).toFixed(2)}Mb`)
            loadFinish = true

            gsap.to('.Loader', {
                opacity: 0,
                ease: 'power1.inOut',
                duration: 1.5,
                onStart() {
                    rifada_referencia.setAttribute('data-state', 'Ready')
                    setTimeout( lataEntrance, 200)
                },
                onComplete() {
                    this.targets()[0].remove()
                }
            }, 1)
        }
    }


    // EnvMap ------------------------------------------------------------------

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    const envObj = "02b.hdr"
    Env_loader
        .setDataType(THREE.UnsignedByteType)
        .load(`/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/envmap/${envObj}`,
            (env) => {
                pmremGenerator.compileEquirectangularShader()
                const HDRImap = pmremGenerator.fromEquirectangular(env).texture

                scene.environment = HDRImap
                // scene.background = HDRImap

                HDRImap.dispose()
                pmremGenerator.dispose()
            },
            (xhr) => {
                loadedElements.env = {}
                loadedElements.env.loaded = xhr.loaded
                loadedElements.env.total = xhr.total

                /*if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] EnvMap "${envObj}" \r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }*/
            },
            (error) => {
                console.warn('[CR] EnvMap: Error loading')
                // console.warn( error )
            }
        )

    // GLTF ------------------------------------------------------------------
    // gltfpack -c -vt 14 -vn 10 -kn -i ./dist/assets/glb/01.glb -o ./dist/assets/glb/01c.glb

    const gltf01 = 'Lata_c.glb'
    let lata_model, gotas_model, lata_cont
    GLTF_loader
        .setMeshoptDecoder(MeshoptDecoder)
        .load( `/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/${gltf01}`,
            (gltf) => {
                const model = gltf.scene

                //

                const objmat = model.children[0].children[0].material.map

                lata_model = model.clone()

                lata_model.children[1].traverse( (m) => {
                    if( m.isMesh ) {
                        // m.material = mat.gotas_mat
                        m.visible = false
                    }
                })

                refeSetings.mat.map.repeat.copy( objmat.repeat )
                lata_model.children[0].children[0].material = refeSetings.mat
                lata_model.rotation.copy( hv.interna.lata.rotI )

                // gotas_model = model.children[1].clone()
                // gotas_model.rotation.copy( hv.interna.lata.rotI )

                lata_cont = new THREE.Object3D()
                
                lata_cont.position.y = 12.5
                lata_cont.rotation.y = Math.PI
                if(isM) lata_cont.position.z = -4
                
                lata_cont.add( lata_model )
                // lata_cont.add( gotas_model )
                scene.add( lata_cont )

            },
            (xhr) => {
                loadedElements.glb_lata = {}
                loadedElements.glb_lata.loaded = xhr.loaded
                loadedElements.glb_lata.total = xhr.total

                /*if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] glb_lata "${gltf01}"\r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }*/
            },
            (error) => {
                console.warn('[CR] glb_lata: Error loading')
                // console.warn( error )
            }
        )

    const gltf02 = 'Frutas_c.glb'
    let fruit_models = []
    GLTF_loader
        .setMeshoptDecoder(MeshoptDecoder)
        .load( `/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/glb/${gltf02}`,
            (gltf) => {
                const model = gltf.scene

                const vr = model.getObjectByName('Limon_01C').children[0].material.map.repeat
                mat.limon_mat.map.repeat = vr
                mat.lima_mat.map.repeat = vr
                mat.toronja_mat.map.repeat = vr
                mat.mango_mat.map.repeat = vr
                mat.pina_mat.map.repeat = vr
                mat.fresa_mat.map.repeat = vr
                mat.cereza_mat.map.repeat = vr


                // fruit_models.push( model.getObjectByName('Limon_01C').clone() )
                fruit_models.push( model.getObjectByName('Limon_02M').clone() )
                fruit_models.push( model.getObjectByName('Limon_03R').clone() )
                fruit_models.push( model.getObjectByName('Limon_04T').clone() )
                fruit_models[0].children[0].material = mat.limon_mat
                fruit_models[1].children[0].material = mat.limon_mat
                fruit_models[2].children[0].material = mat.limon_mat

                // fruit_models.push( model.getObjectByName('Lima_01C').clone() )
                fruit_models.push( model.getObjectByName('Limon_02M').clone() )
                fruit_models.push( model.getObjectByName('Limon_03R').clone() )
                fruit_models.push( model.getObjectByName('Limon_04T').clone() )
                fruit_models[3].name = 'Lima_02M'
                fruit_models[4].name = 'Lima_03R'
                fruit_models[5].name = 'Lima_04T'
                fruit_models[3].children[0].material = mat.lima_mat
                fruit_models[4].children[0].material = mat.lima_mat
                fruit_models[5].children[0].material = mat.lima_mat

                // fruit_models.push( model.getObjectByName('Toronja_01C').clone() )
                fruit_models.push( model.getObjectByName('Toronja_02M').clone() )
                fruit_models.push( model.getObjectByName('Toronja_03R').clone() )
                fruit_models.push( model.getObjectByName('Toronja_04T').clone() )
                fruit_models[6].children[0].material = mat.toronja_mat
                fruit_models[7].children[0].material = mat.toronja_mat
                fruit_models[8].children[0].material = mat.toronja_mat

                fruit_models.push( model.getObjectByName('Mango_').clone() )
                fruit_models[9].children[0].material = mat.mango_mat

                fruit_models.push( model.getObjectByName('Pina_').clone() )
                fruit_models[10].children[0].material = mat.pina_mat

                fruit_models.push( model.getObjectByName('Fresa_').clone() )
                fruit_models[11].children[0].material = mat.fresa_mat

                fruit_models.push( model.getObjectByName('Cereza_').clone() )
                fruit_models[12].children[0].material = mat.cereza_mat

                fruit_models.push( model.getObjectByName('Calories_mesh').clone() )
                fruit_models[13].children[0].material = mat.values_mat
                fruit_models.push( model.getObjectByName('Alcohol_mesh').clone() )
                fruit_models[14].children[0].material = mat.values_mat

                createFruits()

            },
            (xhr) => {
                loadedElements.glb_frutas = {}
                loadedElements.glb_frutas.loaded = xhr.loaded
                loadedElements.glb_frutas.total = xhr.total

                /*if (xhr.loaded === xhr.total) {
                    //* console.log(`[CR] glb_frutas "${gltf02}"\r\n      ${(xhr.total / 1024 / 1024).toFixed(2)}Mb`)
                }*/
            },
            (error) => {
                console.warn('[CR] glb_frutas: Error loading')
                // console.warn( error )
            }
        )

    
    //
    // SET UP

    let entranceFinish = false
    function lataEntrance() {

        let v = 7.5
        if(isM) v = 7

        const tl_lata = gsap.timeline({
            defaults: {
                duration: 3
            }
        })
        tl_lata.to( lata_cont.position, {
            y: v,
            ease: 'power1.out'
        }, 0)
        tl_lata.to( lata_cont.rotation, {
            y: 0,
            ease: 'power1.out',
            onComplete() {
                entranceFinish = true
            }
        }, 0)

        // Loop
        tl_lata.to( lata_cont.position, {
             y: '+=0.75',
             ease: 'power1.inOut',
             yoyo: true,
             repeat: -1
        }, 3)
        tl_lata.to( lata_model.rotation, {
            x: hv.interna.lata.rotF.x,
            y: hv.interna.lata.rotF.y,
            z: hv.interna.lata.rotF.z,
            duration: 4,
            ease: 'power1.inOut',
            yoyo: true,
            repeat: -1
        }, 3)

    }

    const fruits = []

    const fruits_cont = new THREE.Object3D()
    function createFruits() {

        const randomRot = (min=Math.PI/2, max=Math.PI) => { return Math.random() * (max - min) + min }
        const cantidad = Object.keys( hv.interna.frutas ).length
        
        fruits_cont.position.y = 8
        if(isM) fruits_cont.position.z = -4
        fruits_cont.rotation.y = Math.PI / 4

        for( let i = 0; i < cantidad; i++) {
            fruits[i] = fruit_models[ refeSetings.fruits[i] ].clone()

            // fruits[i].visible = false

            fruits[i].position.copy( hv.interna.frutas[i] )
            // fruits[i].rotation.set( Math.PI/4, Math.PI/4, Math.PI/4 )
            fruits[i].rotation.set( randomRot(Math.PI/4, Math.PI/2), randomRot(-Math.PI/4, Math.PI/4), randomRot() )
            fruits[i].scale.set( 0.65, 0.65, 0.65 )

            //

            if( i == 1 ) fruits[i].rotation.set(Math.PI/8, 0, -Math.PI/4)

            switch (origin) {
                case 'lima-limon':
                    //
                    break;
                case 'limon-toronja':
                    if( i == 2 ) fruits[i].rotation.set(Math.PI/8, 0, Math.PI/8)
                    break;
                case 'mango-pina':
                    if( i == 2 ) fruits[i].scale.set(0.9, 0.9, 0.9)
                    if( i == 3 ) fruits[i].rotation.set(0, 0, Math.PI/8)
                    break;
                case 'frutos-rojos':
                    if( i == 0 ) fruits[i].rotation.set(0, 0, Math.PI/8)
                    if( i == 2 ) fruits[i].rotation.set(0, 0, 0)
                    if( i == 3 ) fruits[i].rotation.set(Math.PI, 0, -Math.PI/8)
                    break;
            
                default:
                    console.error('createFruits() NO origin match')
                    break;
            }


            if( i == 6 ) {
                fruits[i].visible = true
                fruits[i].rotation.set(-0.35, 0.5, 0.15)
            }
            if( i == 7 ) {
                fruits[i].visible = true
                fruits[i].rotation.set(-0.45,-0.6, -0.25)
                fruits[i].scale.set( 0.5, 0.5, 0.5 )
            }
            
            fruits_cont.add( fruits[i] )

            if( i < 5 ) {
                gsap.to( fruits[i].rotation, {
                    x: randomRot(),
                    y: randomRot(),
                    duration: randomRot(10, 12),
                    ease: 'power1.inOut',
                    yoyo: true,
                    repeat: -1,
                })
            } /*else {
                gsap.to( fruits[i].rotation, {
                    y: randomRot(),
                    duration: randomRot(8, 10),
                    ease: 'power1.inOut',
                    yoyo: true,
                    repeat: -1,
                })
            }*/


        }

        scene.add(fruits_cont)

        //

        const tl_frutas = gsap.timeline({
            defaults: {
                duration: 3
            }
        })
        tl_frutas.to( fruits_cont.position, {
            y: 0,
            ease: 'power1.out'
        }, 0)
        tl_frutas.to( fruits_cont.rotation, {
            y: 0,
            ease: 'power1.out'
        }, 0)

        // console.log(fruits)
    }
    

    //
    // Desktop------------------------------------------------------------------

    let mousePosition = {x: size.halfW, y: 0}

    function onMouseDown(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                //
                break
        }
    }

    function onMouseMovement(e) {
        switch (e.pointerType) {
            case 'mouse':
            case 'pen':
                // To camera movement + raycast
                let rect = renderer.domElement.getBoundingClientRect()
                mousePosition.x = ( ( e.clientX - rect.left ) / rect.width ) * 2 - 1
                mousePosition.y = - ( ( e.clientY - rect.top ) / rect.height ) * 2 + 1

                // if( entranceFinish == true ) {
                //     lata_cont.rotation.y = ( mousePosition.x * 0.1 )
                //     lata_cont.rotation.x = mousePosition.y *  0.1
    
                //     fruits_cont.rotation.y = ( mousePosition.x * 0.1 ) * 1.75
                //     fruits_cont.rotation.x = lata_cont.rotation.x 
                // }

                break
        }
    }

    rifada_referencia.addEventListener( 'pointerdown', onMouseDown, false)
    rifada_referencia.addEventListener( 'pointermove', onMouseMovement, false )



    // Render ------------------------------------------------------------------

    const renderPass = new RenderPass(scene, camera)

    const noiseEffect = new NoiseEffect({ premultiply: true })
    noiseEffect.blendMode.opacity.value = 0.5

    const toneMappingEffect = new ToneMappingEffect({
        mode: ToneMappingMode.ACES_FILMIC,
        resolution: 128
        // blendFunction: BlendFunction.NORMAL
    })

    const bloomEffect = new BloomEffect({
        intensity: 0.15,
        luminanceThreshold: 0.25,
        luminanceSmoothing: 0.7,
        kernelSize: KernelSize.HUGE
    })

    const dofEffect = new DepthOfFieldEffect( camera, {
        focusDistance: 0.15,
        focalLength: 0.45,
        bokehScale: 4
    })

    const ePass = new EffectPass( 
        camera,
        // noiseEffect,
        // bloomEffect,
        toneMappingEffect,
    )

    composer = new EffectComposer(renderer, {
        frameBufferType: THREE.HalfFloatType
    })
    composer.addPass( renderPass )
    composer.addPass( ePass )

    // ---------------------------------------------------------------------------

    function onWindowResize() {
        if(!isM) getRealSize()
        
        camera.aspect = size.w / size.h
        camera.updateProjectionMatrix()
        renderer.setSize( size.w, size.h )
        composer.setSize( size.w, size.h )
    }
    window.addEventListener( 'resize', onWindowResize, false)


    // ---------------------------------------------------------------------------

    function animate() {
        requestAnimationFrame( animate )

        // stats.update()

        if(loadFinish == false) realLoader()

        //

        composer.render( clock.getDelta() )
        // renderer.render( scene, camera )
    }
    animate()
}

export { init }