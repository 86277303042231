import * as React from "react"

function App() {
    return (
    <>

        <main id='rifada-referencia' data-state='loading'>

            <div id="deviceRotated">
                <span>
                    <img src="/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/images/deviceRotated.svg" />
                    Rota tu dispositivo.
                </span>
            </div>

            <div className='Loader splash-container'>
                <span className='loaderLogo'>
                <div className="copyLoader">
                    <p>AGUA CARBONATADA CON ALCOHOL</p>
                    <div className="copyLoader--Items">
                        <div>
                            <h2>JUGO</h2>
                            <p>NATURAL</p>
                        </div>
                        <div>
                            <h2>90</h2>
                            <p>CALORIAS</p>
                        </div>
                    </div>
                </div>
                </span>
                <span className='loadedValue'>0%</span>
                <div className='loaderCircle'> </div>
            </div>

            <div className='splash-container'>
                <video autoPlay muted loop className="splash-video">
                    <source src="/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/video/bg-video.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>


            <canvas id='canvas-referencia'></canvas>

            <div className='carrousel carrousel-prev'>
                <span className='arrow arrow-prev'></span>
            </div>
            <div className='carrousel carrousel-next'>
                <span className='arrow arrow-next'></span>
            </div>
            <div className='pagination'> </div>

            <div className='refe-text'>
                <h1>el sabor<span>de rifarse</span></h1>
                <div className="copyReferencia">
                    <p>AGUA CARBONATADA CON ALCOHOL</p>
                    <div className="copyReferencia--Items">
                        <div>
                            <h2>JUGO</h2>
                            <p>NATURAL</p>
                        </div>
                        <div>
                            <h2>90</h2>
                            <p>CALORIAS</p>
                        </div>
                    </div>
                </div>
                <a className='button-shop' href='https://www.beerhouse.mx/' target='_blank'>consíguelas aquí</a>
            </div>

            <div className='Loader outModal'>
                <span className='loaderLogo'> </span>
            </div>

        </main>
        
    </>
    )
}

export default App