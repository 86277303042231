import * as React from "react"

function App() {
    return (
    <>

        <main id='rifada-home' data-state='loading'>

            <div id="deviceRotated">
                <span>
                    <img src="/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/images/deviceRotated.svg" />
                    Rota tu dispositivo.
                </span>
            </div>

            <div className='Loader splash-container'>
                <span className='loaderLogo'> 
                <div className="copyLoader">
                    <p>AGUA CARBONATADA CON ALCOHOL</p>
                    <div className="copyLoader--Items">
                        <div>
                            <h2>JUGO</h2>
                            <p>NATURAL</p>
                        </div>
                        <div>
                            <h2>90</h2>
                            <p>CALORIAS</p>
                        </div>
                    </div>
                </div>
                </span>
                
                <span className='loadedValue'>0%</span>
                <div className='loaderCircle'> </div>
            </div>

            <div className='splash-container'>
                <video autoPlay muted loop className="splash-video">
                    <source src="/modules/custom/co_bavaria_arcade_150/3D/corona_rifada/dist/assets/video/bg-video.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>


            <div className='sec-02-background'> </div>

            <div className='sec-03 sec-03-backText'><h2>#el sabor</h2></div>


            <canvas id='canvas-home'></canvas>


            <div className='scroll-cta'>
                Haz scroll
            </div>

            <div className='progress-bar'> </div>

            <div className='sec-01'>
                <h1>el sabor<br />de rifarse</h1>
                <a className='button-more'>conócelas</a>
            </div>


            <div className='sec-02 sec-02-left sec-02-sphere sec-02-01-sphere'>
                <div className='content'>
                    <span>4,2%</span>
                    alc. vol.
                </div>
            </div>
            <div className='sec-02 sec-02-right sec-02-text sec-02-01-text'>
                <h2>
                    <span>Agua<br />
                    carbonatada</span>
                    con alcohol<br />
                </h2>
                {/* <a className='button-more'>conócelas</a> */}
            </div>

            <div className='sec-02 sec-02-left sec-02-sphere sec-02-02-sphere'>
                <div className='content'>
                    <span>4</span>
                    sabores
                </div>
            </div>
            <div className='sec-02 sec-02-right sec-02-text sec-02-02-text'>
                <h2>
                    con
                    <span>Jugo<br/>
                    de
                    </span>
                </h2>
                {/* <a className='button-more'>conócelas</a> */}
            </div>

            <div className='sec-02 sec-02-left sec-02-text sec-02-03-text'>
                <h2>
                    y
                    <span>tan<br/>
                    sólo
                    </span>
                </h2>
            </div>
            <div className='sec-02 sec-02-right sec-02-sphere sec-02-03-sphere'>
                <div className='content'>
                    <span>90</span>
                    calorías
                    {/* <a className='button-more'>conócelas</a> */}
                </div>
            </div>

            <div className='sec-02 sec-02-left sec-02-sphere sec-02-04-sphere'>
                <div className='content'>
                    <span>100%</span>
                </div>
            </div>
            <div className='sec-02 sec-02-right sec-02-text sec-02-04-text'>
                <h2>
                    <span>fa</span>
                </h2>
                {/* <a className='button-more'>conócelas</a> */}
            </div>


            <div className='sec-03 sec-03-frontText'>de rifarse</div>
            <div className='sec-03 sec-03-buttons'>
                <a className='button-shop' href='https://www.beerhouse.mx/' target='_blank'>consíguelas aquí</a>
                {/* <a className='button-more'>conócelas</a> */}
            </div>

            <div className='outModal'>
                <span className='loaderLogo'> </span>
            </div>
            
        </main>
        
    </>
    )
}

export default App