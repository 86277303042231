import { distanceAndSkiddingToXY } from "@popperjs/core/lib/modifiers/offset"
import * as React from "react"
import * as ReactDom from "react-dom"
import View  from "../views/home"

ReactDom.render(<View /> , document.getElementById('app'))

//

export const page = async () => {
	require( "./home.js" ).init()
}